import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/index";
import VueTranslate from "vue-translate-plugin";
import "./plugins/vuetify-mask.js";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

const { fre } = require("./locales/fre");
const { swa } = require("./locales/swa");

Vue.use(VueTranslate);
Vue.use(VueMeta, {
  keyName: "metaInfo",
});

Vue.locales({
  fre: fre,
  swa: swa,
});

export const theApp = new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
