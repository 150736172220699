<template>
  <v-app class="background-light-blue notch">
    <v-main>
      <v-app-bar
        class="background-blue"
        dark
        absolute
        elevate-on-scroll
        scroll-target="#main-body"
        ref="appDiv"
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-center font-weight-bold">{{
          t($route.meta.title)
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-img
          :src="require('@/assets/eac_t.png')"
          max-height="50"
          max-width="50"
        ></v-img>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="background-light-blue"
          >
            <v-list-item v-if="isLoggedIn">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="$router.push('/profile')">{{
                t("Profile")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="$router.push('/about')">{{
                t("About")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-help</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="$router.push('/faq')">{{
                t("FAQ")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isLoggedIn">
              <v-list-item-icon>
                <v-icon>mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="$router.push('/login')">{{
                t("Login")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isLoggedIn">
              <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="$router.push('/register')">{{
                t("Register")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isLoggedIn">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="$router.push('/complaints/view')">{{
                t("Dashboard")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isLoggedIn">
              <v-list-item-icon>
                <v-icon>mdi-comment-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="$router.push('/complaints/log')">{{
                t("Log a Complaint")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="selectLanguage">
              <v-list-item-icon>
                <v-icon>mdi-translate</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ t("Languages") }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isLoggedIn">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="logout">{{
                t("Logout")
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-sheet id="main-body" class="overflow-y-auto">
        <v-container class="overflow-visible" mt-14>
          <router-view></router-view>
        </v-container>
      </v-sheet>
      <v-bottom-navigation class="background-blue" v-model="value" fixed grow>
        <v-btn
          v-if="isLoggedIn"
          value="view"
          @click="$router.push('/complaints/view')"
        >
          <span class="white--text">{{ t("Dashboard") }}</span>

          <v-icon color="white" large>mdi-view-dashboard</v-icon>
        </v-btn>
        <v-btn v-else value="login" @click="$router.push('/login')">
          <span class="white--text">{{ t("Login") }}</span>

          <v-icon color="white" large>mdi-login</v-icon>
        </v-btn>

        <v-btn
          v-if="isLoggedIn"
          value="log"
          @click="$router.push('/complaints/log')"
        >
          <span class="white--text">{{ t("Log a Complaint") }}</span>

          <v-icon color="white" large>mdi-comment-edit</v-icon>
        </v-btn>
        <v-btn v-else value="register" @click="$router.push('/register')">
          <span class="white--text">{{ t("Register") }}</span>

          <v-icon color="white" large>mdi-account-plus</v-icon>
        </v-btn>

        <v-btn value="about" @click="$router.push('/about')">
          <span class="white--text">{{ t("About") }}</span>

          <v-icon color="white" large>mdi-information-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <v-snackbar v-model="popup">
        <div class="text-center">
          <v-icon large>{{ popupIcon }}</v-icon
          >&nbsp;&nbsp;{{ popupMessage }}
        </div>
      </v-snackbar>
      <no-connection :show="!onLine"></no-connection>
      <overlay-custom
        :active="loading"
        :message="loadingMessage"
      ></overlay-custom>
      <v-bottom-sheet v-model="apiError">
        <v-sheet scrollable class="overflow-y-auto rounded">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-card class="ma-0 pa-0">
                <v-card-title class="text-h5 red lighten-2">
                  {{ t("Error!") }}
                </v-card-title>

                <v-card-text class="background-white">
                  <message-alert
                    alert-type="error"
                    :message="t('An error has occurred, please try again...')"
                  ></message-alert>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dismissError">
                    {{ t("Continue") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-bottom-sheet>
      <v-dialog v-model="selectingLanguage" persistent max-width="290">
        <v-card>
          <v-card-text class="pa-0 language-card">
            <v-list class="mt-2" flat subheader three-line>
              <v-list-item-group active-class="background-light-blue">
                <v-list-item
                  v-for="language in languages"
                  :key="language.key"
                  :class="{
                    'background-light-blue': language.key === lang,
                  }"
                  @click="changeLanguage(language.key)"
                >
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        t(language.description)
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mt-8">
                      <v-checkbox
                        :input-value="(active = language.key === lang)"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="
                selectingLanguage = false;
                group = null;
              "
            >
              {{ t("Cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NoConnection from "./components/alert/NoConnection";
import OverlayCustom from "./components/OverlayCustom";
import MessageAlert from "./components/alert/MessageAlert";
import { mapErrors } from "@/lib/formUtils";

export default {
  name: "AppView",
  components: { MessageAlert, OverlayCustom, NoConnection },
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1, viewport-fit=cover, , minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
      },
    ],
  },
  data() {
    return {
      drawer: false,
      group: null,
      value: null,
      popup: false,
      popupMessage: null,
      popupIcon: null,
      onLine: navigator.onLine,
      showBackOnline: false,
      loading: false,
      loadingMessage: null,
      selectingLanguage: false,
      languages: [
        { key: "eng", description: "English" },
        { key: "fre", description: "French" },
        { key: "swa", description: "Swahili" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
    }),
    ...mapState({
      apiError: "apiError",
      lang: "lang",
    }),
  },
  async mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);

    await this.callLookups();
  },
  methods: {
    async callLookups() {
      this.loadingMessage = this.t("Loading...");
      this.loading = true;

      try {
        await this.$store.dispatch("callLookups", {
          force: true,
        });
        await this.$store.dispatch("callFaq", { force: true });
      } catch (error) {
        mapErrors(error.data);
      } finally {
        this.loading = false;
        this.loadingMessage = null;
      }
    },
    selectLanguage() {
      this.selectingLanguage = true;
    },
    async changeLanguage(languageSelected) {
      this.$store.commit("setLang", languageSelected);
      this.$translate.setLang(languageSelected);
      await this.callLookups();
      this.selectingLanguage = false;
      this.group = null;
    },
    dismissError() {
      this.$store.commit("setApiError", false);
      this.loading = false;
      this.loadingMessage = null;
    },
    logout() {
      const that = this;

      this.$store
        .dispatch("logout")
        .then(() => {
          that.$router.push("/");
        })
        .catch(() => {});
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        if (this.$route.query.popup) {
          this.popupMessage = this.$route.query.message;
          this.popupIcon =
            "error" === this.$route.query.type
              ? "mdi-alert-decagram"
              : "mdi-information-outline";
          this.popup = true;
        }
      }
    },
    onLine(to) {
      if (to) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
    showBackOnline(to) {
      if (to) {
        this.popupMessage = this.t("Connected!");
        this.popupIcon = "mdi-information-outline";
        this.popup = true;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style scoped>
.background-blue {
  background-color: #48a6db !important;
  border-color: #48a6db !important;
}
.background-light-blue,
.container {
  background-color: #def3ff !important;
}
div >>> .v-card__title {
  background-color: #025da8 !important;
  color: #ffffff !important;
  word-break: break-word !important;
}
div >>> .v-card__text {
  padding-top: 10px !important;
}
.background-white,
.background-white > .container {
  background-color: #ffffff !important;
}
.v-card >>> .language-card {
  padding-top: 0 !important;
}
@supports (padding: max(0px, 0px)) {
  .notch {
    padding-top: env(safe-area-inset-top) !important;
  }
}
</style>
