<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ t("NTB Type") }}</v-list-item-title>
              <v-list-item-subtitle>{{ ntbType.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ t("Date of Incident") }}</v-list-item-title>
              <v-list-item-subtitle>{{
                complaintInfo[2].date_of_incident
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                t("Country/Region of Incident")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ country.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ t("Type of Location") }}</v-list-item-title>
              <v-list-item-subtitle>{{
                locationType.name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ t("Location") }}</v-list-item-title>
              <v-list-item-subtitle>{{ location.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ t("Complaint") }}</v-list-item-title>
              <v-list-item-subtitle>{{
                complaintInfo[3].complaint
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="undefined !== tariffCode">
            <v-list-item-content>
              <v-list-item-title>{{ t("Tariff Code") }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ tariffCode.heading }}:
                {{ tariffCode.description }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="complaintInfo[4].product_description">
            <v-list-item-content>
              <v-list-item-title>{{
                t("Product description")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                complaintInfo[4].product_description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                t("Cost/Value of goods in USD")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                cost.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ t("Occurrence") }}</v-list-item-title>
              <v-list-item-subtitle>{{ occurrence.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ t("Time lost") }}</v-list-item-title>
              <v-list-item-subtitle>{{ lostTime.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                t("Money lost in USD")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ lostMoney.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                t("Exact value of loss")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                complaintInfo[5].loss_amount
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                t("Description of how the loss was calculated")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                complaintInfo[5].loss_calc_desc
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { find, isEmpty } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardComplete",
  components: { MessageAlert },
  emits: ["set-title"],
  props: {
    complaintInfo: {
      type: Object,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "REVIEW COMPLAINT SUMMARY",
    };
  },
  mounted() {
    this.$emit("set-title", { title: this.title });
  },
  computed: {
    ...mapState({
      lookups: (state) => state.lookups,
    }),
    country() {
      const that = this;

      return find(this.lookups.countries, function (o) {
        return o.id === that.complaintInfo[2].country_id;
      });
    },
    locationType() {
      const that = this;

      return find(this.lookups.location_types, function (o) {
        return o.id === that.complaintInfo[2].location_type_id;
      });
    },
    location() {
      const that = this;

      if (isEmpty(that.complaintInfo[2].location_id)) {
        return {
          name: that.complaintInfo[2].other_location,
        };
      } else {
        return find(this.lookups.locations, function (o) {
          return o.id === that.complaintInfo[2].location_id;
        });
      }
    },
    tariffCode() {
      const that = this;

      return find(this.lookups.tariff_codes, function (o) {
        return o.id === that.complaintInfo[4].tariff_code_id;
      });
    },
    cost() {
      const that = this;

      return find(this.lookups.cost_types, function (o) {
        return o.id === that.complaintInfo[4].cost_type_id;
      });
    },
    ntbType() {
      const that = this;

      return find(this.lookups.ntb_types_all, function (o) {
        return o.id === that.complaintInfo[1].ntb_type_id;
      });
    },
    occurrence() {
      const that = this;

      return find(this.lookups.occurrences, function (o) {
        return o.id === that.complaintInfo[5].occurrence_id;
      });
    },
    lostTime() {
      const that = this;

      return find(this.lookups.lost_times, function (o) {
        return o.id === that.complaintInfo[5].lost_time_id;
      });
    },
    lostMoney() {
      const that = this;

      return find(this.lookups.lost_monies, function (o) {
        return o.id === that.complaintInfo[5].lost_money_id;
      });
    },
  },
};
</script>

<style scoped>
ion-list {
  background: white;
}
ion-card {
  background: white;
}
ion-card-header {
  font-weight: bold;
  color: black;
  background: #ffb400;
}
ion-card-content {
  background: white;
}
</style>
