<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          alt="EAC"
          :src="require('@/assets/EAC_logo_transp.png')"
          max-width="200"
          contain
          class="mx-auto"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title>{{ t("Frequently Asked Questions") }}</v-card-title>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(answer, question) in faq"
                :key="question"
              >
                <v-expansion-panel-header>
                  {{ question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ answer }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AboutView",
  computed: {
    ...mapState({
      faq: (state) => state.faq,
    }),
  },
};
</script>
