<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="forgotPasswordDetails.email"
          :label="t('Email Address')"
          :error-messages="getErrorMessages('email')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          :disabled="!canSubmit"
          color="success"
          class="mr-4"
          @click="submit"
          block
        >
          <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{ t("Submit") }}
        </v-btn>
      </v-col>
    </v-row>
    <overlay-custom
      :active="loading"
      :message="loadingMessage"
    ></overlay-custom>
  </v-container>
</template>

<script>
import MessageAlert from "../components/alert/MessageAlert";
import { isEmpty } from "lodash";
import { mapErrors } from "@/lib/formUtils";
import OverlayCustom from "../components/OverlayCustom";

export default {
  name: "ForgotPasswordView",
  components: { OverlayCustom, MessageAlert },
  data() {
    return {
      forgotPasswordDetails: {
        email: null,
      },
      errors: [],
      errorMessage: null,
      loading: false,
      loadingMessage: null,
    };
  },
  computed: {
    canSubmit() {
      return !isEmpty(this.forgotPasswordDetails.email);
    },
  },
  methods: {
    async submit() {
      const that = this;
      that.errorMessage = null;
      that.errors = [];
      that.loading = true;
      that.loadingMessage = that.t("Submitting...");

      this.$store
        .dispatch("forgotPassword", this.forgotPasswordDetails)
        .then((response) => {
          that.$router.push({
            name: "login",
            query: {
              popup: "true",
              message: response.data.data.message,
              type: "info",
            },
          });
        })
        .catch((error) => {
          that.mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
          that.loadingMessage = null;
        });
    },
    mapErrors(errorDetails) {
      this.errors = mapErrors(errorDetails);
      this.errorMessage = errorDetails.message;
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
};
</script>
