<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="showDatePicker"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="step2.date_of_incident"
              :label="t('Incident Date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :error-messages="getErrorMessages('date_of_incident')"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="step2.date_of_incident"
            @input="changeDate()"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="step2.country_id"
          :items="countryList"
          :label="t('Country/Region of Incident')"
          :error-messages="getErrorMessages('country_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="step2.location_type_id"
          :items="locationTypeList"
          :label="t('Type of Location')"
          :error-messages="getErrorMessages('location_type_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="showLocations">
      <v-col cols="12">
        <v-select
          v-model="step2.location_id"
          :items="locations"
          :label="t('Location')"
          :error-messages="getErrorMessages('location_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="showLocationOther">
      <v-col cols="12">
        <v-text-field
          v-model="step2.other_location"
          :label="t('Other Location')"
          :error-messages="getErrorMessages('other_location')"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty, filter, isNull, map } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardStep2",
  components: { MessageAlert },
  emits: ["values-changed", "set-title"],
  props: {
    dateSelected: {
      type: String,
      default: null,
    },
    countrySelected: {
      type: String,
      default: null,
    },
    locationSelected: {
      type: String,
      default: null,
    },
    locationTypeSelected: {
      type: String,
      default: null,
    },
    otherLocation: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "SELECT DATE AND LOCATION",
      step: 2,
      showDatePicker: false,
      step2: {
        date_of_incident: null,
        country_id: null,
        location_id: null,
        location_type_id: null,
        other_location: null,
      },
    };
  },
  computed: {
    ...mapState({
      lookups: (state) => state.lookups,
    }),
    countryList() {
      return map(
        !isNull(this.lookups.countries) ? this.lookups.countries : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    locationTypeList() {
      return map(
        !isNull(this.lookups.location_types) ? this.lookups.location_types : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    locations() {
      const that = this;
      let locationOptions = filter(
        !isNull(this.lookups.locations) ? this.lookups.locations : [],
        function (o) {
          return (
            o.location_type_id === that.step2.location_type_id &&
            o.country_id === that.step2.country_id
          );
        }
      );

      return map(locationOptions, function (lookup) {
        return {
          text: lookup.name,
          value: lookup.id,
        };
      });
    },
    showLocations() {
      return !isEmpty(this.locations);
    },
    showLocationOther() {
      return !this.showLocations && !isEmpty(this.step2.location_type_id);
    },
  },
  mounted() {
    this.step2.date_of_incident = this.dateSelected;
    this.step2.country_id = this.countrySelected;
    this.step2.location_id = this.locationSelected;
    this.step2.location_type_id = this.locationTypeSelected;
    this.step2.other_location = this.otherLocation;

    this.$emit("values-changed", { data: this.step2, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    changeDate() {
      this.showDatePicker = false;
      this.$emit("values-changed", { data: this.step2, step: this.step });
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
    change($event) {
      this.step2.country_id = $event.target.value;
      this.$emit("values-changed", { data: this.step2, step: this.step });
    },
    changeLocation($event) {
      this.step2.location_id = $event.target.value;
      this.step2.other_location = null;
      this.$emit("values-changed", { data: this.step2, step: this.step });
    },
    changeType($event) {
      this.step2.location_type_id = $event.target.value;
      this.step2.other_location = null;
      this.$emit("values-changed", { data: this.step2, step: this.step });
    },
  },
  watch: {
    showLocationOther(newVale) {
      if (newVale) {
        this.step2.location_id = null;
      } else {
        this.step2.other_location = null;
      }
    },
  },
};
</script>

<style scoped></style>
