<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="step5.occurrence_id"
          :items="occurrenceList"
          :label="t('Occurrences in the last 12 months?')"
          :error-messages="getErrorMessages('occurrence_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="step5.lost_time_id"
          :items="lostTimeList"
          :label="t('Time lost as a result of this incident?')"
          :error-messages="getErrorMessages('lost_time_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="step5.lost_money_id"
          :items="lostMoneyList"
          :label="t('Money lost as a result of this incident in USD?')"
          :error-messages="getErrorMessages('lost_money_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field-money
          v-model="step5.loss_amount"
          :label="t('Exact value of loss')"
          v-bind:properties="{
            prefix: '$',
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: true,
            errorMessages: getErrorMessages('loss_amount'),
          }"
          v-bind:options="{
            length: 10,
            precision: 2,
            empty: 0,
          }"
          @change="change"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="step5.loss_calc_desc"
          :label="t('Briefly describe how the loss has been calculated')"
          :error-messages="getErrorMessages('loss_calc_desc')"
          @change="change"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty, map, isNull } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardStep5",
  components: { MessageAlert },
  emits: ["values-changed", "set-title"],
  props: {
    occurrenceSelected: {
      type: Number,
      default: null,
    },
    lostTimeSelected: {
      type: Number,
      default: null,
    },
    lostMoneySelected: {
      type: Number,
      default: null,
    },
    lossAmountEntered: {
      type: String,
      default: null,
    },
    lossCalcDescEntered: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "OCCURRENCES",
      step: 5,
      step5: {
        lost_money_id: null,
        lost_time_id: null,
        occurrence_id: null,
        loss_amount: null,
        loss_calc_desc: null,
      },
    };
  },
  computed: {
    ...mapState({
      lookups: (state) => state.lookups,
    }),
    occurrenceList() {
      return map(
        !isNull(this.lookups.occurrences) ? this.lookups.occurrences : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    lostTimeList() {
      return map(
        !isNull(this.lookups.lost_times) ? this.lookups.lost_times : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    lostMoneyList() {
      return map(
        !isNull(this.lookups.lost_monies) ? this.lookups.lost_monies : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
  },
  mounted() {
    this.step5.occurrence_id = this.occurrenceSelected;
    this.step5.lost_time_id = this.lostTimeSelected;
    this.step5.lost_money_id = this.lostMoneySelected;
    this.step5.loss_amount = this.lossAmountEntered;
    this.step5.loss_calc_desc = this.lossCalcDescEntered;

    this.$emit("values-changed", { data: this.step5, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    selectOccurrence($event) {
      this.step5.occurrence_id = $event.target.value;
      this.$emit("values-changed", { data: this.step5, step: this.step });
    },
    selectLostTime($event) {
      this.step5.lost_time_id = $event.target.value;
      this.$emit("values-changed", { data: this.step5, step: this.step });
    },
    selectLostMoney($event) {
      this.step5.lost_money_id = $event.target.value;
      this.$emit("values-changed", { data: this.step5, step: this.step });
    },
    change() {
      this.$emit("values-changed", { data: this.step5, step: this.step });
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
};
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
  margin: 0px !important;
  border-radius: 0px !important;
}
</style>
