/* eslint-disable */

const swa = {
    "No internet connection": "Hakuna muunganisho wa mtandao",
    "About Us": "Kuhusu sisi",
    "Login": "Kuingia",
    "Register": "Sajili",
    "View Complaints": "Tazama malalamiko",
    "Log a Complaint": "Ingiza lalamiko",
    "Forgot Password": "Kusahau nenosiri",
    "Profile": "Wasifu",
    "NTB Type": "Aina ya Kikwazo kisicho cha ushuru NTB",
    "Date of Incident": "Tarehe ya Tukio",
    "Country/Region of Incident": "Nchi/Eneo la tukio",
    "Type of Location": "Aina ya mahali",
    "Location": "Mahali",
    "Complaint": "Lalamiko",
    "Tariff Code": "Msimbo wa ushuru",
    "Cost/Value of goods in USD": "Gharama au thamani ya bidhaa kwa Dola ya Kimarekani",
    "Occurrence": "Tukio",
    "Time lost": "Muda uliopotea",
    "Money lost in USD": "Hela zilizopotea kwa dola ya kimarekani",
    "REVIEW COMPLAINT SUMMARY": "HAKIKI MUHTASARI WA LALAMIKO",
    "Select other NTB type": "Chagua aina nyingine ya kikwazo kisicho cha Ushuru",
    "SELECT NTB TYPE": "Chagua aina  ya kikwazo kisicho cha Ushuru",
    "Incident Date": "Tarehe ya Tukio",
    "Other Location": "Mahali pengine",
    "SELECT DATE AND LOCATION": "CHAGUA TAREHE NA MAHALI",
    "Complaint Details (max 300 words)": "Maelezo ya lalamiko (idadi ya  Juu kabisa  maneno 300 )",
    "Please grant the app the photos/files permissions to upload evidence": "Tafadhali iruhusu programu picha / mafaili ruhusa ya kupakia ushahidi",
    "TYPE COMPLAINT": "CHARAZA LALAMIKO",
    "Word count": "Hesabu ya maneno",
    "GO BACK": "RUDI",
    "SELECT TARIFF CODE & USD": "CHAGUA MSIMBO WA USHURU NA DOLA YA KIMAREKANI",
    "Occurrences in the last 12 months?": "Matukio katika miezi  kumi na miwili iliyopita?",
    "Time lost as a result of this incident?": "Muda uliopotea kwa sababu ya tukio hili?",
    "Money lost as a result of this incident in USD?": "Hela zilizopotea kwa dola za kimarekani  kutokana na  tukio hili?",
    "OCCURRENCES": "MATUKIO ",
    "Previous": "Iliyotangulia",
    "Log Complaint": "Ripoti lalamiko",
    "Next": "Inayofuata",
    "Logging Complaint": "Kuripoti  lalamiko",
    "Validating Complaint": "Thibitisha lalamiko",
    "Complaint created!": "Lalamiko limeundwa!",
    "Complaint updated!": "Lalamiko imesasishwa!",
    "Notifications": "Taarifa",
    "Important message to all users": "Jumbe muhimu kwa watumiaji wote",
    "No Complaints": "Hakuna malalamiko",
    "My Complaints": "Malalamiko yangu",
    "View": "Tazama ",
    "Status": "Hali",
    "Country": "Nchi",
    "Cost in USD": "Gharama  kwa Dola ya Kimarekani",
    "Time Lost": "Muda uliopotea",
    "Money Lost": "Hela zilizopotea",
    "Purpose of the Mobile App": "Nia ya programu ya rununu",
    "This app has been designed to allow users who are citizens of an East African Community (EAC) country to report a Non-Tariff Barrier that you have encountered whilst trading or trying to trade with EAC Partner States.": "Programu hii imeundwa kuwaruhusu watumiaji ambao ni raia wa nchi wanachama za jumuiya ya Afrika Mashariki (EAC) kuripoti Kikwazo kisicho cha kikodi ambacho umekabiliana nacho wakati wa ufanyaji biashara au kujaribu kufanya biashara na nchi wanachama wa jumuiya ya afrika mashariki.",
    "These Partner States are Burundi, DRC, Kenya, Rwanda, South Sudan, Tanzania, and Uganda.": "Nchi hizi wanachama ni Burundi, Jamhuri ya Kidemokrasia ya Kongo, Kenya Rwanda, Sudan Kusini, Tanzania na Uganda.",
    "Instructions to log a complaint": "Maagizo ili kuingiza lalamiko",
    "This app allows you to log and track a complaint you have experienced that you think is an NTB.": "Programu hii inakuruhusu kuingiza na kufuatilia lalamiko ulilopitia ambalo unadhani ni Kikwazo kisicho cha Kikodi",
    "During the process of logging these complaints you will be asked a few questions - please be assured that your anonymity will be ensured, and data protected.": "Wakati wa mchakato wa kuripoti  malalamiko haya utaulizwa maswali machache - Tafadhali uwe na uhakika kuwa ubanaji wa jina lako utahakikishwa, na data kulindwa.",
    "The information you provide will be used in notifying the relevant administrator within the EAC of your issue and they will then work to resolve the issue.": "Taarifa utakayotoa itatumika katika  kumfahamisha msimamizi husika ndani ya Jumuiya ya Afrika Mashariki kuhusu suala lako na kisha  watafanya kazi kulitatua suala.",
    "You can track the status of your complaint using this app, through the home tab and you can log new complaints at anytime.": "Unaweza kufuatilia hali ya lalamiko lako kutumia programu hii,kupitia kichupo cha mwanzo na unaweza kuingiza malalamiko mapya wakati wowote.",
    "Welcome back": "Karibu tena",
    "Unauthorized!": "Isiyoidhinishwa!",
    "Email Address": "Anwani ya barua pepe",
    "Submit": "Wasilisha",
    "Submitting": "Kuwasilisha",
    "Password": "Nywila",
    "Forgot your Password?": "Kusahau nywila yako?",
    "Logging in": "Kuingia ndani",
    "First Name": "Jina la kwanza",
    "Last Name": "Jina la mwisho",
    "Cellphone Number": "Nambari ya rununu",
    "Country of Residence": "Nchi ya makazi",
    "Operator Type": "Aina ya Opereta",
    "Other Operator": "Opereta Nyingine",
    "Gender": "Jinsia",
    "Update Password": "Sasisha nenosiri",
    "Confirm Password": "Thibitisha nenosiri",
    "Update": "Sasisha ",
    "Profile updated": "Wasifu umesasishwa",
    "Female": "Mke",
    "Male": "Mume",
    "Loading": "Kupakia",
    "Updating": "Kusasisha",
    "Registering": "Kusajili",
    "Registration complete": "Usajili umekamilika",
    "About": "Kuhusu ",
    "Logout": "Toka",
    "Error": "Hitilafu",
    "An error has occurred, please try again": "Hitilafu imetokea ,tafadhali jaribu tena",
    "Continue": "Endelea",
    "Connected": "Imeunganishwa",
    "Choose your language": "Chagua lugha yako",
    "Airport": "Uwanja wa ndege",
    "Border post": "Kituo cha mpakani",
    "Seaport": "Bandari",
    "Government institution": "Taasisi ya serikali",
    "Non-government institution": "Taasisi isiyo ya serikali",
    "Road block": "Kizuizi cha barabarani",
    "Weighbridge": "Mizani ya  kupimia uzito wa magari",
    "Government Policy and regulations": "Sera na kanuni za Serikali",
    "Administrative (Border Operating Hours, delays at ...": "Utawala (Saa za Uendeshaji Mipakani, kucheleweshwa kwa …",
    "Immigration requirements (Visa, travel permit)": "Mahitaji ya uhamiaji(visa,kibali cha kusafiri)",
    "Transport related corruption": "Ufisadi unaohusiana na usafiri",
    "Infrastructure (Air, Port, Rail, Road, Border Post...": "Miundombinu (Hewa, Bandari, Reli, Barabara, Kituo cha Mpaka…",
    "Vehicle standards": "Viwango vya magari",
    "Issues related to transit": "Masuala yanayohusiana na upitishaji bidhaa",
    "Customs Clearance Procedures": "Taratibu za forodha",
    "Import/Export Regulations": "Kanuni za maduhuli/mahuruji",
    "Non-Tariff Fees": "Ada zisizo za ushuru",
    "Sanitary and Phytosanitary Measures": "Hatua za afya  na Afya ya Mimea ",
    "Technical Barriers to Trade": "Vikwazo vya kiufundi kwa biashara",
    "Transit Fees": "Ada za upitishaji",
    "Government participation in trade & restrictive pr...": "Ushiriki wa Serikali katika biashara na vitendo vya kuzuia",
    "Customs and administrative entry procedures": "Taratibu za Kuingia za Forodha na Kiutawala",
    "Technical barriers to trade (TBT)": "Vikwazo vya kiufundi kwa biashara(TBT)",
    "Sanitary & phyto-sanitary (SPS) measures": "Hatua ya afya  na Afya ya Mimea (SPS)",
    "Specific limitations": "Vikomo hususa",
    "Charges on imports": "Ada kwa maduhuli",
    "Other procedural problems": "Matatizo  mengine ya kiutaratibu",
    "Export subsidies": "Ruzuku ya maduhuli",
    "Government monopoly in export/import": "Ukiritimba wa serikali katika mahuruji / maduhuli",
    "State subsidies, procurement, trading, state owner...": "Ruzuku za serikali, ununuzi, kufanya biashara, Umiliki wa serikali...",
    "Transport, Clearing and Forwarding": "Usafirishaji, Usafishaji na Usambazaji mizigo",
    "Preference given to domestic bidders/suppliers": "Upendeleo unatolewa kwa wazabuni/wasambazaji wa ndani",
    "Requirement for counter trade": "Mahitaji ya biashara ya kimataifa kwa kubadilishana bidhaa badala ya kununua sarafu.",
    "Domestic assistance programmes for companies": "Programu za usaidizi wa ndani kwa makampuni",
    "Discriminatory or flawed government procurement po...": "Sera za kibaguzi au zenye kasoro za manunuzi ya Serikali…",
    "Import bans": "Marufuku ya uingizaji bidhaa kutoka nje",
    "Determination of eligibility of an exporting count...": "Uamuzi wa kustahiki kwa nchi inayouza nje",
    "Determination of eligibility of an exporting estab...": "Uamuzi wa kustahiki kwa shirika la kuuza nje…",
    "Occupational safety and health regulation": "Udhibiti wa usalama na afya kazini",
    "Multiplicity and Controls of Foreign exchange mark...": "Wingi na Udhibiti wa soko la fedha za kigeni...",
    "\"Buy national\" policy": "Sera ya \"Nunua kitaifa\".",
    "Lack of coordination between government institutio...": "Ukosefu wa uratibu kati ya taasisi za serikali...",
    "Other": "Nyingine",
    "Government imposing antidumping duties": "Serikali kutoza Ushuru wa kupambana na bidhaa duni",
    "Arbitrary customs classification": "Uainishaji wa forodha wa kiholela",
    "Issues related to the rules of origin": "Masula yanayohusiana na sheria za asili",
    "Import licensing": "Utoaji wa leseni za uagizaji",
    "Decreed customs surcharges": "Malipo ya ziada ya forodha yaliyoamriwa",
    "Additional taxes and other charges": "Ushuru wa ziada na ada nyingine",
    "International taxes and charges levied on imports ...": "Ushuru wa kimataifa na ada zinazotozwa maduhuli ...",
    "Lengthy and costly customs clearance procedures": "Taratibu za muda mrefu na za gharama kubwa za kibali cha forodha ",
    "Issues related to transit fees": "Masuala yanayohusiana na ada za upitishaji bidhaa",
    "Inadequate or unreasonable customs procedures and ...": "Taratibu duni za forodha au zisizofaa na …",
    "Lack of control in Customs infrastructure": "Ukosefu wa udhibiti wa miundombinu ya Forodha",
    "Lack of capacity of Customs officers": "Ukosefu wa uwezo wa maafisa wa Forodha",
    "Issues related to Pre-Shipment Inspections": "Masuala yanayohusiana na Ukaguzi wa Kabla ya Usafirishaji",
    "Restrictive technical regulations and standards no...": "Vizuizi vya kanuni na viwango vya kiufundi",
    "Inadequate or unreasonable testing and certificati...": "Upimaji usiofaa au usio na maana na uthibitisho",
    "Standards disparities": "Tofauti za viwango",
    "Intergovernmental acceptance of testing methods an...": "Ukubali wa mbinu za upimaji baina ya serikali",
    "Issues related to packaging, labeling and marking": "Masuala yanayohusiana na ufungaji, kuweka lebo na kuweka alama",
    "Conformity assessment related to TBT": "Tathmini ya Ulinganifu inayohusiana na Vikwazo vya Kiufundi kwenye Biashara (TBT)",
    "Inadequate infrastructure": "Miundombinu duni",
    "Issues related to sanitary and phyto-sanitary measures (SPS)": "Masuala yanayohusiana na Hatua za afya  na Afya ya Mimea ",
    "Conformity assessment related to SPS": "Tathmini ya Ulinganifu inayohusiana na SPS",
    "Quantitative restrictions": "Vizuizi vya kiasi",
    "Exchange controls": "Udhibiti wa kubadilishana",
    "Export taxes": "Ushuru wa kuuza nje",
    "Quotas": "Vikomo",
    "Import licensing requirements": "Mahitaji ya leseni ya kuagiza",
    "Proportion restrictions of foreign to domestic goods...": "Vizuizi vya uwiano wa bidhaa za kigeni kwa bidhaa za ndani...",
    "Minimum import price limits": "Kikomo cha chini kabisa cha bei ya kuagiza",
    "Embargoes": "Vikwazo",
    "Non-automatic licensing": "Utoaji leseni usio wa kiotomatiki",
    "Prohibitions": "Makatazo",
    "Quantitative safeguard measures": "Hatua za ulinzi wa kiasi",
    "Export restraint arrangements": "Mipangilio ya vizuizi vya uuzaji nje ya nchi",
    "Other quantity control measures": "Hatua nyingine za udhibiti wa kiasi",
    "Restrictive licenses": "Leseni zenye vikwazo",
    "Prior import deposits and subsidies": "Amana za awali za uagizaji bidhaa kutoka nje na ruzuku",
    "Administrative fees": "Ada za usimamizi",
    "Special supplementary duties": "Ushuru spesheli ya ziada",
    "Import credit discriminations": "Ubaguzi katika utoaji mikopo ya uagizaji bidhaa kutoka nje",
    "Variable levies": "Tozo zinazobadilika",
    "Border taxes": "Ushuru za mpakani",
    "Arbitrariness": "Utumizi mbaya wa mamlaka",
    "Discrimination": "Ubaguzi ",
    "Corruption": "Ufisadi",
    "Costly procedures": "Taratibu za gharama",
    "Lengthy procedures": "Taratibu zinazochukua muda mrefu",
    "Lack of information on procedures (or changes ther...": "Ukosefu wa habari juu ya taratibu za (au mabadiliko..",
    "Complex variety of documentation required": "Aina tata za nyaraka zinazohitajika",
    "Consular and Immigration Issues": "Masuala ya ubalozi na uhamiaji",
    "Inadequate trade related infrastructure": "Miundombinu isiyotosha inayohusiana na  biashara",
    "Costly Road user charges /fees": "Gharama /Malipo ya juu ya utumiaji wa barabara",
    "Nothing": "Hakuna kitu",
    "Informal trader": "Mfanyabiashara asiye rasmi",
    "Small scale trader": "Mfanyabiashara mdogo",
    "Commercial trader": "Mfanyabiashara mkubwa",
    "Transporter": "Msafirishaji",
    "Clearing agent or freight forwarder": "Wakala wa kusafisha na kusafirisha mizigo",
    "This is the first time": "Hii ni mara ya kwanza",
    "Once before": "Mara moja kabla ya",
    "A few times": "Mara chache",
    "Every month": "Kila mwezi",
    "every day": "Kila siku",
    "$xx or above": "$xx au zaidi",
    "X to X": "X hadi X",
    "Exact value of loss": "Thamani halisi ya hasara",
    "Briefly describe how the loss has been calculated": "Eleza kwa ufupi jinsi hasara ilivyohesabiwa",
    "Evidence": "Ushahidi",
    "Description of how the loss was calculated": "Maelezo ya jinsi hasara ilivyohesabiwa",
    "Message": "Ujumbe",
    "Loading complaint...": "Inapakia malalamiko...",
    "Product description": "Maelezo ya bidhaa",
    "Search for Tariff Codes": "Tafuta Misimbo ya Ushuru",
    "Frequently Asked Questions": "Maswali Yanayoulizwa Mara Kwa Mara",
    "Languages": "Lugha",
    "English": "Kiingereza",
    "French": "Kifaransa",
    "Swahili": "Kiswahili",
    "Cancel": "Ghairi",
    "Search HS code or description": "Tafuta msimbo au maelezo ya HS",
    "Attached files": "Faili zilizoambatishwa",
    "Dashboard": "Dashibodi"
};

module.exports = {swa};
