<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-alert border="left" colored-border :type="alertType" elevation="2">
          {{ message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MessageAlert",
  props: {
    message: {
      type: String,
      required: true,
    },
    alertType: {
      type: String,
      required: true,
    },
  },
};
</script>
