<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="step3.complaint"
          :label="t('Complaint Details (max ' + maxWords + ' words)')"
          :hint="wordCountText"
          @keydown="limit($event, 'complaint')"
          :error-messages="getErrorMessages('complaint')"
          @change="change"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row v-if="!isNative || permissionsGranted">
      <v-col cols="12">
        <v-file-input
          v-model="step3.documents"
          counter
          multiple
          show-size
          truncate-length="15"
          :label="t('Evidence')"
          :error-messages="getErrorMessages('documents')"
          @change="change"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <message-alert
          :message="
            t(
              'Please grant the app the photos/files permissions to upload evidence'
            )
          "
          alert-type="error"
        />
      </v-col>
    </v-row>
    <v-row v-if="isEditing">
      <v-col cols="12">
        <span class="font-weight-bold">{{ t("Attached files") }}</span>
        <v-list>
          <v-list-item
            class="px-0"
            v-for="document in listDocs"
            :key="document.id"
          >
            <v-list-item-avatar class="mr-0">
              <v-icon>mdi-file</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ document.file_name }}</v-list-item-title>

              <v-list-item-subtitle>
                {{
                  formatDate(document.created_at, "DD MMMM YYYY @ HH:mm")
                }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action class="ml-0">
              <v-btn-toggle tile color="primary" group>
                <v-btn icon @click="download(document.id, document.file_name)">
                  <v-icon color="warning">mdi-download</v-icon>
                </v-btn>

                <v-btn icon @click="deleteDoc(document.id)">
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MessageAlert from "../../../components/alert/MessageAlert";
import { isEmpty, includes, filter, cloneDeep, isNull } from "lodash";
import { Camera } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import moment from "moment";
import { mapErrors } from "@/lib/formUtils";

export default {
  name: "WizardStep3",
  components: { MessageAlert },
  emits: ["values-changed", "set-title"],
  props: {
    complaintEntered: {
      type: String,
      default: null,
    },
    attachedDocs: {
      type: Array,
      default: null,
    },
    documentsEntered: {
      type: Array,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "TYPE COMPLAINT",
      step: 3,
      step3: {
        complaint: null,
        documents: [],
      },
      maxWords: 300,
      permissionsGranted: false,
      listDocs: [],
    };
  },
  computed: {
    isNative() {
      return Capacitor.isNativePlatform();
    },
    wordCount() {
      if (!isEmpty(this.step3.complaint)) {
        let tmpString = this.step3.complaint;
        tmpString = tmpString.replace(/(^\s*)|(\s*$)/gi, "");
        tmpString = tmpString.replace(/[ ]{2,}/gi, " ");
        tmpString = tmpString.replace(/(?:\r\n|\r|\n)/g, " ");
        return tmpString.split(" ").filter(function (str) {
          return str !== "";
        }).length;
      }
      return 0;
    },
    wordCountText() {
      return this.t("Word count") + ": " + this.wordCount;
    },
  },
  async mounted() {
    if (this.isNative) {
      await this.requestPermissions();
    }

    this.step3.complaint = this.complaintEntered;
    this.step3.documents = this.documentsEntered;
    this.listDocs = cloneDeep(this.attachedDocs);
    this.change();

    this.$emit("values-changed", { data: this.step3, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    async requestPermissions() {
      const fileSystemPermissions = await Filesystem.requestPermissions();
      const cameraPermissions = await Camera.requestPermissions();

      this.permissionsGranted =
        "granted" === fileSystemPermissions.publicStorage &&
        "granted" === cameraPermissions.camera &&
        "granted" === cameraPermissions.photos;
    },
    change() {
      let formData = new FormData();
      formData.append("documents[0]", "");

      if (!isNull(this.step3.documents)) {
        for (var i = 0; i < this.step3.documents.length; i++) {
          let file = this.step3.documents[i];

          formData.append("documents[" + i + "]", file);
        }
      }

      formData.append(
        "complaint",
        isNull(this.step3.complaint) ? "" : this.step3.complaint
      );

      this.$emit("values-changed", {
        data: this.step3,
        step: this.step,
        formData: formData,
      });
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
    limit(event, dataProp) {
      const keyCode = event.key || event.code;
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowDown",
        "ArrowUp",
        "ArrowRight",
        "ArrowLeft",
      ];

      if (
        !isEmpty(this.step3[dataProp]) &&
        this.wordCount >= this.maxWords &&
        !includes(allowedKeys, keyCode)
      ) {
        event.preventDefault();
      }
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    deleteDoc(documentId) {
      this.$store
        .dispatch("deleteDocument", {
          documentId: documentId,
          token: this.token,
        })
        .then((response) => {
          if (response.data.data.completed === true) {
            const newDocs = filter(this.listDocs, function (o) {
              return o.id !== documentId;
            });

            this.listDocs = Object.assign({}, cloneDeep(newDocs));
          }
        })
        .catch((error) => {
          mapErrors(error.data);
        });
    },
    download(documentId, fileName) {
      this.$store
        .dispatch("downloadDocument", {
          documentId: documentId,
          token: this.token,
        })
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");

          link.href = href;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((error) => {
          mapErrors(error.data);
        });
    },
  },
};
</script>

<style scoped>
.v-list-item__action > div > button {
  min-width: 10px !important;
}
</style>
