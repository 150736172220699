var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.errorMessage)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('message-alert',{attrs:{"message":_vm.errorMessage,"alert-type":"error"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.occurrenceList,"label":_vm.t('Occurrences in the last 12 months?'),"error-messages":_vm.getErrorMessages('occurrence_id')},model:{value:(_vm.step5.occurrence_id),callback:function ($$v) {_vm.$set(_vm.step5, "occurrence_id", $$v)},expression:"step5.occurrence_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.lostTimeList,"label":_vm.t('Time lost as a result of this incident?'),"error-messages":_vm.getErrorMessages('lost_time_id')},model:{value:(_vm.step5.lost_time_id),callback:function ($$v) {_vm.$set(_vm.step5, "lost_time_id", $$v)},expression:"step5.lost_time_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.lostMoneyList,"label":_vm.t('Money lost as a result of this incident in USD?'),"error-messages":_vm.getErrorMessages('lost_money_id')},model:{value:(_vm.step5.lost_money_id),callback:function ($$v) {_vm.$set(_vm.step5, "lost_money_id", $$v)},expression:"step5.lost_money_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-money',{attrs:{"label":_vm.t('Exact value of loss'),"properties":{
          prefix: '$',
          readonly: false,
          disabled: false,
          outlined: false,
          clearable: true,
          errorMessages: _vm.getErrorMessages('loss_amount'),
        },"options":{
          length: 10,
          precision: 2,
          empty: 0,
        }},on:{"change":_vm.change},model:{value:(_vm.step5.loss_amount),callback:function ($$v) {_vm.$set(_vm.step5, "loss_amount", $$v)},expression:"step5.loss_amount"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.t('Briefly describe how the loss has been calculated'),"error-messages":_vm.getErrorMessages('loss_calc_desc')},on:{"change":_vm.change},model:{value:(_vm.step5.loss_calc_desc),callback:function ($$v) {_vm.$set(_vm.step5, "loss_calc_desc", $$v)},expression:"step5.loss_calc_desc"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }