<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <div v-if="null !== lookups">
      <v-row v-for="ntbType in lookups.ntb_types" :key="ntbType.id">
        <v-col cols="12">
          <v-card
            elevation="2"
            @click="selectNtbType(ntbType.id, false)"
            :class="{ 'tile-selected': isSelected(ntbType.id) }"
          >
            <img
              v-if="typeExists(ntbType.position)"
              :alt="determineImage(map[ntbType.position].image)"
              :src="
                require('@/assets/' +
                  determineImage(map[ntbType.position].image))
              "
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card
            elevation="2"
            @click="selectOther"
            :class="{ 'tile-selected': step1.other_selected }"
          >
            <img
              alt="other"
              :src="require('@/assets/' + determineImage('Other'))"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="step1.other_selected">
        <v-col cols="12">
          <v-autocomplete
            solo
            clearable
            :items="typeList"
            v-model="step1.ntb_type_id"
            :label="t('Select other NTB type')"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-subtitle
                    @click="selectNtbType(data.item.value, true)"
                    v-html="data.item.text"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="hasError('ntb_type_id')">
        <v-col cols="12">
          <div class="error--text theme--light">
            <span
              v-for="message in getErrorMessages('ntb_type_id')"
              :key="message"
            >
              {{ message }} <br />
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty, forEach, filter, isUndefined } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardStep1",
  components: {
    MessageAlert,
  },
  emits: ["values-changed", "set-title"],
  props: {
    ntbTypeSelected: {
      type: String,
      default: null,
    },
    otherSelected: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "SELECT NTB TYPE",
      step: 1,
      step1: {
        ntb_type_id: null,
        other_selected: false,
      },
      map: {
        83: {
          image: "Administrative_Issues",
          color: "primary",
        },
        2: {
          image: "Immigration_Requirements",
          color: "secondary",
        },
        4: {
          image: "Road_Border_Infrastructure",
          color: "tertiary",
        },
        9: {
          image: "Import_Ban",
          color: "success",
        },
        20: {
          image: "Rules_of_Origin_Issues",
          color: "warning",
        },
        21: {
          image: "Import_Licensing",
          color: "danger",
        },
        25: {
          image: "Lengthy_Costly_Customs_Procedures",
          color: "light",
        },
        30: {
          image: "Poor_Testing_Inspection_Facilities",
          color: "medium",
        },
        63: {
          image: "Administrative_Fees_Levies",
          color: "dark",
        },
        85: {
          image: "Corruption",
          color: "primary",
        },
        75: {
          image: "Lack_of_Clarity_on_Border_Procedures",
          color: "secondary",
        },
      },
    };
  },
  computed: {
    ...mapState({
      lookups: (state) => state.lookups,
      lang: (state) => state.lang,
    }),
    typeList() {
      const that = this;
      let list = [];

      forEach(this.lookups.ntb_types_all, function (data) {
        if (isEmpty(data.parent_id)) {
          let children = filter(that.lookups.ntb_types_all, function (o) {
            return o.parent_id === data.id;
          });

          if (!isEmpty(children)) {
            list.push({
              header: data.name,
            });

            forEach(children, function (child) {
              list.push({
                text: child.name,
                value: child.id,
                group: data.name,
              });
            });

            list.push({
              divider: true,
            });
          }
        }
      });

      return list;
    },
  },
  mounted() {
    this.step1.ntb_type_id = this.ntbTypeSelected;
    this.step1.other_selected = this.otherSelected;
    this.$emit("values-changed", { data: this.step1, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    typeExists(index) {
      return !isUndefined(this.map[index]);
    },
    determineImage(imageName) {
      return imageName + "_" + this.lang + ".svg";
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
    selectNtbType(id, other) {
      this.step1.other_selected = other;
      this.step1.ntb_type_id = id;
      this.$emit("values-changed", { data: this.step1, step: this.step });
    },
    isSelected(id) {
      return this.step1.ntb_type_id === id;
    },
    selectOther() {
      this.step1.ntb_type_id = null;
      this.step1.other_selected = true;
    },
  },
};
</script>

<style scoped>
.tile-selected {
  border: solid 3px #52ff00 !important;
}
div >>> .v-subheader {
  font-weight: bold !important;
}
</style>
