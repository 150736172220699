<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row v-if="showProductDescription">
      <v-col cols="12">
        <v-textarea
          v-model="step4.product_description"
          :label="t('Product description')"
          :error-messages="getErrorMessages('product_description')"
          @change="change"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="step4.cost_type_id"
          :items="costTypeList"
          :label="t('Cost/Value of goods in USD')"
          :error-messages="getErrorMessages('cost_type_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="searchedTariffCode"
          clearable
          solo
          :placeholder="t('Search for Tariff Codes')"
          prepend-icon="mdi-database-search"
          :items="lookups.tariff_codes"
          item-text="description"
          item-value="id"
          :filter="filterMethod"
          :no-data-text="t('Search HS code or description')"
        >
          <template v-slot:item="data">
            {{ data.item.heading }}: {{ data.item.description }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <v-btn v-if="showPrevious" @click="selectTariffCode(currentParentId)">
            <v-icon>mdi-backup-restore</v-icon>&nbsp;&nbsp;{{ t("GO BACK") }}
          </v-btn>
        </div>
        <v-list>
          <v-list-item-group
            v-model="temp_tariff_code_id"
            v-bind="{ mandatory: null !== step4.tariff_code_id }"
          >
            <v-list-item
              :color="check(tariffCode.id)"
              v-for="tariffCode in tariffCodes"
              :key="tariffCode.id"
              :value="tariffCode.id"
              @click="selectTariffCode(tariffCode.id)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="tariffCode.heading"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="tariffCode.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="null === step4.tariff_code_id">
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-row v-if="hasError('tariff_code_id')">
          <v-col cols="12">
            <div class="error--text theme--light">
              <span
                v-for="message in getErrorMessages('tariff_code_id')"
                :key="message"
              >
                {{ message }} <br />
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { filter, isEmpty, head, map, isNull } from "lodash";
import MessageAlert from "../../../components/alert/MessageAlert";

export default {
  name: "WizardStep4",
  components: { MessageAlert },
  emits: ["values-changed", "set-title"],
  props: {
    tariffCodeIdSelected: {
      type: String,
      default: null,
    },
    productDescriptionEntered: {
      type: String,
      default: null,
    },
    costTypeSelected: {
      type: Number,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "SELECT TARIFF CODE & USD",
      step: 4,
      step4: {
        tariff_code_id: null,
        cost_type_id: null,
        product_description: null,
      },
      selected_tariff_code: null,
      previous_tariff_code: null,
      temp_tariff_code_id: null,
      searchedTariffCode: null,
      filteredTariffCodes: null,
    };
  },
  computed: {
    ...mapState({
      lookups: (state) => state.lookups,
    }),
    showPrevious() {
      return null !== this.selected_tariff_code;
    },
    showProductDescription() {
      return isEmpty(this.step4.tariff_code_id);
    },
    currentParentId() {
      const idToSearch = !isEmpty(this.tariffCodes)
        ? head(this.tariffCodes).parent_id
        : null;
      const code = filter(this.lookups.tariff_codes, function (o) {
        return o.id === idToSearch;
      });

      return isEmpty(code) ? null : head(code).parent_id;
    },
    tariffCodes() {
      const that = this;

      return filter(this.lookups.tariff_codes, function (o) {
        return !isEmpty(that.searchedTariffCode)
          ? o.id === that.searchedTariffCode
          : o.parent_id === that.selected_tariff_code;
      });
    },
    costTypeList() {
      return map(
        !isNull(this.lookups.cost_types) ? this.lookups.cost_types : [],
        function (lookup) {
          return {
            text: lookup.description,
            value: lookup.id,
          };
        }
      );
    },
  },
  mounted() {
    const that = this;
    this.temp_tariff_code_id = this.tariffCodeIdSelected;
    this.step4.tariff_code_id = this.tariffCodeIdSelected;
    this.step4.product_description = this.productDescriptionEntered;
    this.step4.cost_type_id = this.costTypeSelected;
    const code = filter(this.lookups.tariff_codes, function (o) {
      return o.id === that.tariffCodeIdSelected;
    });

    if (!isEmpty(code)) {
      this.selectTariffCode(head(code).parent_id, false);
    }

    this.$emit("values-changed", { data: this.step4, step: this.step });
    this.$emit("set-title", { title: this.title });
  },
  methods: {
    filterMethod(item, queryText) {
      return (
        item.description
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.heading
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    check(id) {
      return this.step4.tariff_code_id === id ? "green" : "";
    },
    selectTariffCode(id, reset = true) {
      if (this.hasChildren(id)) {
        this.previous_tariff_code = this.selected_tariff_code;
        this.selected_tariff_code = id;
        this.searchedTariffCode = null;

        if (reset) {
          this.step4.tariff_code_id = null;
        }
      } else {
        this.step4.tariff_code_id = id;
      }

      this.$emit("values-changed", { data: this.step4, step: this.step });
    },
    change() {
      this.$emit("values-changed", { data: this.step4, step: this.step });
    },
    selectCostType($event) {
      this.step4.cost_type_id = $event.target.value;
      this.$emit("values-changed", { data: this.step4, step: this.step });
    },
    hasChildren(id) {
      return !isEmpty(
        filter(this.lookups.tariff_codes, function (o) {
          return o.parent_id === id;
        })
      );
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
  watch: {
    showProductDescription(newVale) {
      if (!newVale) {
        this.step4.product_description = null;
      }
    },
  },
};
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
  margin: 0px !important;
  border-radius: 0px !important;
}
</style>
