<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          alt="EAC"
          :src="require('@/assets/EAC_logo_transp.png')"
          max-width="200"
          contain
          class="mx-auto"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title>{{ t("Purpose of the Mobile App") }}</v-card-title>
          <v-card-text>
            <p>
              {{
                t(
                  "This app has been designed to allow users who are citizens of an East African Community (EAC) country to report a Non-Tariff Barrier that you have encountered whilst trading or trying to trade with EAC Partner States."
                )
              }}
            </p>
            <p>
              {{
                t(
                  "These Partner States are Burundi, DRC, Kenya, Rwanda, South Sudan, Tanzania, and Uganda."
                )
              }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title>{{
            t("Instructions to log a complaint")
          }}</v-card-title>
          <v-card-text>
            <p>
              {{
                t(
                  "This app allows you to log and track a complaint you have experienced that you think is an NTB."
                )
              }}
            </p>

            <p>
              {{
                t(
                  "During the process of logging these complaints you will be asked a few questions - please be assured that your anonymity will be ensured, and data protected."
                )
              }}
            </p>

            <p>
              {{
                t(
                  "The information you provide will be used in notifying the relevant administrator within the EAC of your issue and they will then work to resolve the issue."
                )
              }}
            </p>

            <p>
              {{
                t(
                  "You can track the status of your complaint using this app, through the home tab and you can log new complaints at anytime."
                )
              }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutView",
};
</script>
