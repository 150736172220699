<template>
  <v-container :class="['pt-0', showFloatingButtons ? 'mb-16' : '']">
    <div></div>
    <overlay-custom
      class="pt-0"
      :active="loading"
      :message="loadingMessage"
    ></overlay-custom>
    <div ref="scrollstartposition"></div>
    <v-row v-if="!loading">
      <v-col cols="12" class="pt-0">
        <div class="text-center font-weight-bold">
          {{ currentStep }}. {{ t(currentStepTitle) }}
        </div>
        <v-stepper alt-labels class="my-3">
          <v-stepper-header>
            <template v-for="stepIndex in totalSteps">
              <v-stepper-step
                :key="`${stepIndex}-step`"
                :step="stepIndex"
                :complete="currentStep > stepIndex"
                :class="currentStep === stepIndex ? 'active-step' : ''"
              ></v-stepper-step>

              <v-divider
                v-if="stepIndex < totalSteps"
                :key="stepIndex"
              ></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
        <v-btn
          v-if="currentStep !== 1"
          small
          @click="backward"
          class="float-left"
        >
          <span
            ><v-icon small>mdi-chevron-double-left</v-icon>
            {{ t("Previous") }}</span
          >
        </v-btn>
        <v-btn small @click="validateSave" class="float-right">
          <span v-if="lastStep"
            >{{ t("Log Complaint") }}
            <v-icon small>mdi-check-circle-outline</v-icon></span
          >
          <span v-else
            >{{ t("Next") }}
            <v-icon small>mdi-chevron-double-right</v-icon></span
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card elevation="2">
          <wizard-step1
            v-if="currentStep === 1"
            @values-changed="mapValues"
            @set-title="setTitle"
            :ntb-type-selected="getData('ntb_type_id', 1)"
            :other-selected="getData('other_selected', 1)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step1>
          <wizard-step2
            v-if="currentStep === 2"
            @values-changed="mapValues"
            @set-title="setTitle"
            :country-selected="getData('country_id', 2)"
            :date-selected="getData('date_of_incident', 2)"
            :location-selected="getData('location_id', 2)"
            :location-type-selected="getData('location_type_id', 2)"
            :other-location="getData('other_location', 2)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step2>
          <wizard-step3
            v-if="currentStep === 3"
            @values-changed="mapValues"
            @set-title="setTitle"
            :attached-docs="attachedDocs"
            :complaint-entered="getData('complaint', 3)"
            :documents-entered="getData('documents', 3)"
            :errors="errors"
            :error-message="errorMessage"
            :is-editing="isEditing"
          ></wizard-step3>
          <wizard-step4
            v-if="currentStep === 4"
            @values-changed="mapValues"
            @set-title="setTitle"
            :tariff-code-id-selected="getData('tariff_code_id', 4)"
            :cost-type-selected="getData('cost_type_id', 4)"
            :product-description-entered="getData('product_description', 4)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step4>
          <wizard-step5
            v-if="currentStep === 5"
            @values-changed="mapValues"
            @set-title="setTitle"
            :occurrence-selected="getData('occurrence_id', 5)"
            :lost-money-selected="getData('lost_money_id', 5)"
            :lost-time-selected="getData('lost_time_id', 5)"
            :loss-amount-entered="getData('loss_amount', 5)"
            :loss-calc-desc-entered="getData('loss_calc_desc', 5)"
            :errors="errors"
            :error-message="errorMessage"
          ></wizard-step5>
          <wizard-complete
            v-if="lastStep"
            @set-title="setTitle"
            :complaint-info="dataToSubmit"
            :error-message="errorMessage"
          ></wizard-complete>
          <v-btn
            v-if="showFloatingButtons && currentStep !== 1"
            fixed
            bottom
            left
            small
            color="primary"
            class="float-back-button"
            @click="backward"
            @scroll="onScroll"
          >
            <span
              ><v-icon small>mdi-chevron-double-left</v-icon>
              {{ t("Previous") }}</span
            >
          </v-btn>
          <v-btn
            v-if="showFloatingButtons"
            fixed
            bottom
            right
            small
            color="primary"
            class="float-next-button"
            @click="validateSave(true)"
            @scroll="onScroll"
          >
            <span v-if="lastStep">
              {{ t("Log Complaint") }}
              <v-icon small>mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              {{ t("Next") }} <v-icon small>mdi-chevron-double-right</v-icon>
            </span>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import WizardStep1 from "./wizard/Step1";
import WizardStep2 from "./wizard/Step2";
import WizardStep3 from "./wizard/Step3";
import WizardStep4 from "./wizard/Step4";
import WizardStep5 from "./wizard/Step5";
import WizardComplete from "./wizard/Complete";
import {
  isUndefined,
  forEach,
  omit,
  isNil,
  forOwn,
  find,
  isNull,
} from "lodash";
import { mapErrors } from "@/lib/formUtils";
import OverlayCustom from "../../components/OverlayCustom";

export default {
  name: "ComplaintLog",
  components: {
    OverlayCustom,
    WizardStep1,
    WizardStep2,
    WizardStep3,
    WizardStep4,
    WizardStep5,
    WizardComplete,
  },
  data() {
    return {
      currentStep: 1,
      currentStepTitle: null,
      totalSteps: 6,
      dataToSubmit: {},
      errorMessage: null,
      errors: [],
      loading: false,
      loadingMessage: null,
      showFloatingButtons: false,
      fieldDependencies: {
        location_type_id: "location",
      },
      attachedDocs: [],
      editingMapData: {
        1: ["ntb_type_id", "other_selected"],
        2: [
          "country_id",
          "date_of_incident",
          "location_id",
          "location_type_id",
          "other_location",
        ],
        3: ["complaint"],
        4: ["tariff_code_id", "cost_type_id", "product_description"],
        5: [
          "occurrence_id",
          "lost_money_id",
          "lost_time_id",
          "loss_amount",
          "loss_calc_desc",
        ],
      },
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.userDetails,
      token: (state) => state.token,
      lookups: (state) => state.lookups,
    }),
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
    }),
    lastStep() {
      return this.currentStep === this.totalSteps;
    },
    isEditing() {
      return !isUndefined(this.$route.params.id);
    },
    complaintId() {
      return isUndefined(this.$route.params.id) ? null : this.$route.params.id;
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push({
        name: "login",
        query: {
          popup: "true",
          message: this.t("Unauthorized!"),
          type: "error",
        },
      });
    }
  },
  created() {
    const that = this;
    window.addEventListener("wheel", this.onScroll);

    if (this.isEditing) {
      const complaintId = this.$route.params.id;
      this.loadingMessage = this.t("Loading complaint...");
      this.loading = true;
      this.$store
        .dispatch("viewComplaint", {
          complaintId: complaintId,
          token: this.token,
        })
        .then((response) => {
          that.attachedDocs = response.data.data.documents;
          forEach(that.editingMapData, function (fields, step) {
            that.dataToSubmit[step] = {};
            forEach(fields, function (field) {
              if (!isUndefined(that.fieldDependencies[field])) {
                that.dataToSubmit[step][field] = !isNull(
                  response.data.data[that.fieldDependencies[field]]
                )
                  ? response.data.data[that.fieldDependencies[field]][field]
                  : "";
              } else {
                that.dataToSubmit[step][field] = response.data.data[field];
              }
            });
          });

          if (isNull(response.data.data.location)) {
            that.dataToSubmit[2]["location_type_id"] = find(
              that.lookups.location_types,
              function (o) {
                return o.name.toLowerCase() === "other";
              }
            ).id;
          }
        })
        .catch((error) => {
          mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
        });
    }
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.onScroll);
  },
  methods: {
    onScroll() {
      this.showFloatingButtons = window.scrollY > 200;
    },
    toTop() {
      this.$refs.scrollstartposition.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.showFloatingButtons = false;
    },
    clearErrors() {
      this.errorMessage = null;
      this.errors = [];
    },
    forward() {
      this.currentStep++;
      this.clearErrors();
    },
    backward() {
      this.currentStep--;
      this.clearErrors();
    },
    mapValues(data) {
      this.dataToSubmit[data.step] = data.data;

      if (!isNil(data.formData)) {
        this.dataToSubmit[data.step]["formData"] = data.formData;
      }
    },
    setTitle(data) {
      this.currentStepTitle = data.title;
    },
    getData(field, stepId) {
      return isUndefined(this.dataToSubmit[stepId])
        ? null
        : this.dataToSubmit[stepId][field];
    },
    mapErrors(errorDetails) {
      this.errors = mapErrors(errorDetails);
      this.errorMessage = this.t(errorDetails.message);
    },
    async validateSave(toTop = false) {
      const that = this;
      that.loading = true;
      that.loadingMessage = that.lastStep
        ? this.t("Logging Complaint...")
        : this.t("Validating Complaint...");
      let stepDataToSubmit = {};
      that.errorMessage = null;
      that.errors = [];

      if (this.lastStep) {
        let finalFormData = new FormData();

        forEach(this.dataToSubmit, function (data, key) {
          if (parseInt(key) !== that.totalSteps) {
            if (!isNil(data.formData)) {
              for (let formDataInfo of data.formData.entries()) {
                finalFormData.append(formDataInfo[0], formDataInfo[1]);
              }
            } else {
              forOwn(data, function (value, field) {
                finalFormData.append(field, value);
              });
            }
          }
        });

        finalFormData.append("user_id", this.userDetails.id);
        finalFormData.append("submit", true);

        if (this.isEditing) {
          finalFormData.append("id", this.complaintId);
        }

        stepDataToSubmit = finalFormData;
      } else {
        stepDataToSubmit = this.dataToSubmit[this.currentStep];
        stepDataToSubmit = omit(stepDataToSubmit, "user_id");
        stepDataToSubmit = omit(stepDataToSubmit, "submit");

        if (this.isEditing) {
          stepDataToSubmit["id"] = this.complaintId;
        }

        if (!isNil(this.dataToSubmit[this.currentStep]["formData"])) {
          stepDataToSubmit = this.dataToSubmit[this.currentStep]["formData"];

          if (this.isEditing) {
            stepDataToSubmit.append("id", this.complaintId);
          }
        }
      }

      this.$store
        .dispatch("wizard", { data: stepDataToSubmit, token: this.token })
        .then((response) => {
          if (response.data.data.next_step) {
            that.forward();
          }

          if (response.data.data.completed) {
            that.$router.push({
              name: "complaints-view",
              query: {
                popup: "true",
                message: this.t(
                  this.isEditing ? "Complaint updated!" : "Complaint created!"
                ),
                type: "info",
              },
            });
          }
        })
        .catch((error) => {
          that.mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
          that.loadingMessage = null;
          if (toTop) {
            that.toTop();
          }
        });
    },
  },
};
</script>

<style scoped>
.v-stepper--alt-labels .v-stepper__step {
  flex-basis: 75px !important;
}
.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 35px -22px 0px !important;
}
.v-stepper__step--complete >>> span {
  background-color: #12a012 !important;
}
.active-step >>> span {
  background-color: red !important;
}
.float-next-button {
  bottom: 75px !important;
  right: 25px !important;
}
.float-back-button {
  bottom: 75px !important;
  left: 25px !important;
}
</style>
