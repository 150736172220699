/* eslint-disable */

const fre = {
    "No internet connection": "Pas de connexion internet",
    "About Us": "À propos de nous",
    "Login": "Se connecter",
    "Register": "S'inscrire",
    "View Complaints": "Consulter les plaintes",
    "Log a Complaint": "Déposer plainte",
    "Forgot Password": "Oublier le mot de passe",
    "Profile": "Profil",
    "NTB Type": "Type de barrière non tarifaire (BNT)",
    "Date of Incident": "Date de l'incident",
    "Country/Region of Incident": "Pays/région de l'incident",
    "Type of Location": "Type d'emplacement",
    "Location": "Emplacement",
    "Complaint": "Réclamation",
    "Tariff Code": "Code tarifaire",
    "Cost/Value of goods in USD": "Coût/valeur des marchandises en USD",
    "Occurrence": "Événement",
    "Time lost": "Temps perdu",
    "Money lost in USD": "Argent perdu en dollars américains (en USD)",
    "REVIEW COMPLAINT SUMMARY": "EXAMEN DU RÉSUMÉ DE LA PLAINTE",
    "Select other NTB type": "Sélectionner un autre type de BNT",
    "SELECT NTB TYPE": "SÉLECTIONNER LE TYPE DE BNT",
    "Incident Date": "Date de l'incident",
    "Other Location": "Autre emplacement",
    "SELECT DATE AND LOCATION": "SÉLECTIONNER LA DATE ET LE LIEU",
    "Complaint Details (max 300 words)": "Détails de la plainte (300 mots maximum)",
    "Please grant the app the photos/files permissions to upload evidence": "Veuillez autoriser l'application à télécharger des photos/fichiers (la preuve)",
    "TYPE COMPLAINT": "Taper Plainte",
    "Word count": "Nombre de mots",
    "GO BACK": "RETOURNER",
    "SELECT TARIFF CODE & USD": "SÉLECTIONNER LE CODE TARIFAIRE & USD",
    "Occurrences in the last 12 months?": "Événements au cours des 12 derniers mois?",
    "Time lost as a result of this incident?": "Temps perdu à la suite de cet incident?",
    "Money lost as a result of this incident in USD?": "Argent perdu à la suite de cet incident en USD?",
    "OCCURRENCES": "ÉVÉNEMENTS",
    "Previous": "Précédent",
    "Log Complaint": "Consignez la plainte",
    "Next": "Suivant",
    "Logging Complaint": "Consigner la plainte",
    "Validating Complaint": "Validation de la plainte",
    "Complaint created!": "Plainte créée!",
    "Complaint updated!": "Plainte mis à jour!",
    "Notifications": "Notifications",
    "Important message to all users": "Message important à tous les utilisateurs",
    "No Complaints": "Aucune plainte",
    "My Complaints": "Mes plaintes",
    "View": "Voir",
    "Status": "Status",
    "Country": "Pays",
    "Cost in USD": "Coût en USD",
    "Time Lost": "Temps perdu",
    "Money Lost": "Argent perdu",
    "Purpose of the Mobile App": "Objectif de l'application mobile",
    "This app has been designed to allow users who are citizens of an East African Community (EAC) country to report a Non-Tariff Barrier that you have encountered whilst trading or trying to trade with EAC Partner States.": "Cette application a été conçue pour permettre aux utilisateurs qui sont citoyens d'un pays membre de la Communauté de l'Afrique de L'Est (CAE) de signaler une Barrièrenon tarifaire (BNT) que vous avez rencontrée tout en faisant des affaires ou en essayant de faire des affaires avec les États partenaires de la CAE.",
    "These Partner States are Burundi, DRC, Kenya, Rwanda, South Sudan, Tanzania, and Uganda.": "Ces États partenaires sont le Burundi, la RDC, le Kenya, le Rwanda, le Soudan du Sud, la Tanzanie et l'Ouganda.",
    "Instructions to log a complaint": "Instructions pour déposer une plainte",
    "This app allows you to log and track a complaint you have experienced that you think is an NTB.": "Cette application vous permet d'enregistrer et de suivre une plainte que vous avez vécue et que vous pensez être une BNT.",
    "During the process of logging these complaints you will be asked a few questions - please be assured that your anonymity will be ensured, and data protected.": "Au cours du processus d'enregistrement de ces plaintes, quelques questions vous seront posées - soyez assuré que votre anonymat sera garanti et que vos données seront protégées.",
    "The information you provide will be used in notifying the relevant administrator within the EAC of your issue and they will then work to resolve the issue.": "Les informations que vous fournissez seront utilisées pour notifier votre problème à l'administrateur compétent au sein de la CAE qui s'efforcera alors de le résoudre.",
    "You can track the status of your complaint using this app, through the home tab and you can log new complaints at anytime.": "Vous pouvez suivre l'état de votre plainte à l'aide de cette application, via l'onglet Accueil, et vous pouvez enregistrer de nouvelles plaintes à tout moment.",
    "Welcome back": "Bienvenue à nouveau",
    "Unauthorized!": "Non autorisé!",
    "Email Address": "Adresse électronique",
    "Submit": "Soumettez ",
    "Submitting": "Soumettre ",
    "Password": "Mot de passe",
    "Forgot your Password?": "Mot de passe oublié?",
    "Logging in": "Se  connecter",
    "First Name": "Prénom",
    "Last Name": "Nom de famille",
    "Cellphone Number": "Numéro de téléphone portable",
    "Country of Residence": "Pays de résidence",
    "Operator Type": "Type d'opérateur",
    "Other Operator": "Autre opérateur",
    "Gender": "Sexe",
    "Update Password": "Mettre à jour le mot de passe",
    "Confirm Password": "Confirmer le mot de passe",
    "Update": "Mettez à jour",
    "Profile updated": "Profil mis à jour",
    "Female": "Femme",
    "Male": "Homme",
    "Loading": "Charger",
    "Updating": "Mise à jour",
    "Registering": "Enregistrement",
    "Registration complete": "Enregistrement terminé",
    "About": "À propos de",
    "Logout": "Se déconnecter",
    "Error": "Erreur",
    "An error has occurred, please try again": "Une erreur s'est produite, veuillez réessayer",
    "Continue": "Continuez",
    "Connected": "Connecté",
    "Choose your language": "Choisissez votre langue",
    "Airport": "Aéroport",
    "Border post": "Poste frontière",
    "Seaport": "Port maritime",
    "Government institution": "Institution gouvernementale",
    "Non-government institution": "Institution non gouvernementale",
    "Road block": "Barrage routier",
    "Weighbridge": "Pont-bascule",
    "Other": "Autre",
    "Government Policy and regulations": "Politiques et réglementations gouvernementales",
    "Administrative (Border Operating Hours, delays at ...": "Administratif (heures d'ouverture des frontières, retards à …",
    "Immigration requirements (Visa, travel permit)": "Exigences en matière d'immigration (visa, permis de voyage)",
    "Transport related corruption": "Corruption liée aux transports",
    "Infrastructure (Air, Port, Rail, Road, Border Post...": "Infrastructures (aériennes, portuaires, ferroviaires, routières, postes frontières...)",
    "Vehicle standards": "Normes applicables aux véhicules",
    "Issues related to transit": "Questions relatives au transit",
    "Customs Clearance Procedures": "Procédures de dédouanement",
    "Import/Export Regulations": "Règlements sur les importations/exportations",
    "Non-Tariff Fees": "Redevances non tarifaires",
    "Sanitary and Phytosanitary Measures": "Mesures sanitaires et phytosanitaires",
    "Technical Barriers to Trade": "Obstacles techniques au commerce",
    "Transit Fees": "Redevances de transit",
    "Government participation in trade & restrictive pr...": "Participation du gouvernement au commerce et aux pratiques restrictives ",
    "Customs and administrative entry procedures": "Procédures douanières et administratives d'entrée",
    "Technical barriers to trade (TBT)": "Obstacles techniques au commerce (OTC)",
    "Sanitary & phyto-sanitary (SPS) measures": "Mesures sanitaires et phytosanitaires (SPS)",
    "Specific limitations": "Limitations spécifiques",
    "Charges on imports": "Taxes sur les importations",
    "Other procedural problems": "Autres problèmes de procédure",
    "Export subsidies": "Subventions à l'exportation",
    "Government monopoly in export/import": "Monopole du gouvernement en matière d'exportation/importation",
    "State subsidies, procurement, trading, state owner...": "Subventions de l'État, achats, commerce, propriété de l'État…",
    "Transport, Clearing and Forwarding": "Transport, dédouanement et acheminement",
    "Preference given to domestic bidders/suppliers": "Préférence accordée aux soumissionnaires/fournisseurs nationaux",
    "Requirement for counter trade": "Exigences en matière de commerce de contrepartie",
    "Domestic assistance programmes for companies": "Programmes d'aide intérieure aux entreprises",
    "Discriminatory or flawed government procurement po...": "Politiques de marchés publics discriminatoires ou défectueuses",
    "Import bans": "Interdictions d'importation",
    "Determination of eligibility of an exporting count...": "Détermination de l'éligibilité d'un pays exportateur...",
    "Determination of eligibility of an exporting estab...": "Détermination de l'éligibilité d'un établissement exportateur...",
    "Occupational safety and health regulation": "Réglementation en matière de sécurité et de santé au travail",
    "Multiplicity and Controls of Foreign exchange mark...": "Multiplicité et contrôles du marché des changes. ",
    "\"Buy national\" policy": "La politique \"Achetons national\" donnant la préférence aux produits nationaux",
    "Lack of coordination between government institutio...": "Manque de coordination entre les institutions gouvernementales",
    "Government imposing antidumping duties": "Gouvernement imposant des droits antidumping",
    "Arbitrary customs classification": "Classification douanière arbitraire",
    "Issues related to the rules of origin": "Questions relatives aux règles d'origine",
    "Import licensing": "La délivrance de licences d'importation",
    "Decreed customs surcharges": "Surtaxes douanières déterminées par un décret ",
    "Additional taxes and other charges": "Taxes supplémentaires et autres frais",
    "International taxes and charges levied on imports ...": "Taxes et redevances internationales prélevées sur les importations …",
    "Lengthy and costly customs clearance procedures": "Procédures de dédouanement longues et coûteuses",
    "Issues related to transit fees": "Questions relatives aux redevances de transit",
    "Inadequate or unreasonable customs procedures and ...": "Procédures douanières inadéquates ou déraisonnables et …",
    "Lack of control in Customs infrastructure": "Manque de contrôle de l'infrastructure douanière",
    "Lack of capacity of Customs officers": "Manque de capacité des agents des douanes",
    "Issues related to Pre-Shipment Inspections": "Questions relatives aux inspections avant expédition",
    "Restrictive technical regulations and standards no...": "Les normes et réglementations techniques restrictives",
    "Inadequate or unreasonable testing and certificati...": "Tests et certifications inadéquats ou déraisonnables…",
    "Standards disparities": "Disparités entre les normes ",
    "Intergovernmental acceptance of testing methods an...": "Acceptation intergouvernementale des méthodes d'essai",
    "Issues related to packaging, labeling and marking": "Questions relatives à l'emballage, à l'étiquetage et au marquage",
    "Conformity assessment related to TBT": "Évaluation de la conformité relative aux obstacles techniques au commerce (OTC)",
    "Inadequate infrastructure": "Infrastructures inadéquates",
    "Issues related to sanitary and phyto-sanitary measures (SPS)": "Issues related to sanitary and phyto-sanitary measures (SPS)",
    "Conformity assessment related to SPS": "Évaluation de la conformité dans le domaine SPS",
    "Quantitative restrictions": "Restrictions quantitatives",
    "Exchange controls": "Contrôle des changes",
    "Export taxes": "Taxes à l'exportation",
    "Quotas": "Quotas",
    "Import licensing requirements": "Exigences en matière de licence d'importation",
    "Proportion restrictions of foreign to domestic goods...": "Restrictions proportionnelles des biens étrangers par rapport aux biens nationaux…",
    "Minimum import price limits": "Limites minimales des prix à l'importation",
    "Embargoes": "Embargos",
    "Non-automatic licensing": "La délivrance non-automatique des licences",
    "Prohibitions": "Les interdictions",
    "Quantitative safeguard measures": "Mesures de sauvegarde quantitatives",
    "Export restraint arrangements": "Dispositifs de restriction des exportations",
    "Other quantity control measures": "Autres mesures de contrôle des quantités",
    "Restrictive licenses": "Licences restrictives",
    "Prior import deposits and subsidies": "Dépôts préalables à l'importation et subventions",
    "Administrative fees": "Frais administratifs",
    "Special supplementary duties": "Droits supplémentaires spéciaux",
    "Import credit discriminations": "Discriminations en matière de crédit à l'importation",
    "Variable levies": "Prélèvements variables",
    "Border taxes": "Des taxes frontalières",
    "Arbitrariness": "Arbitraire",
    "Discrimination": "Discrimination",
    "Corruption": "Corruption ",
    "Costly procedures": "Procédures onéreuses",
    "Lengthy procedures": "Procédures longues",
    "Lack of information on procedures (or changes ther...": "Manque d'information sur les procédures (ou les changements)",
    "Complex variety of documentation required": "Une documentation complexe et variée est nécessaire",
    "Consular and Immigration Issues": "Questions consulaires et d'immigration",
    "Inadequate trade related infrastructure": "Insuffisance des infrastructures liées au commerce",
    "Costly Road user charges /fees": "Des redevances routières coûteuses",
    "Nothing": "Rien",
    "Informal trader": "Commerçant informel",
    "Small scale trader": "Petit commerçant",
    "Commercial trader": "Négociateur commercial",
    "Transporter": "Transporteur",
    "Clearing agent or freight forwarder": "Agent de compensation ou transitaire",
    "This is the first time": "C'est la première fois",
    "Once before": "Une fois auparavant",
    "A few times": "À plusieurs reprises",
    "Every month": "Chaque mois",
    "every day": "Chaque jour",
    "$xx or above": "xx $ ou plus",
    "X to X": "X à X",
    "Exact value of loss": "Valeur exacte de la perte",
    "Briefly describe how the loss has been calculated": "Décrivez brièvement comment la perte a été calculée",
    "Evidence": "Preuve",
    "Description of how the loss was calculated": "Description de la façon dont la perte a été calculée",
    "Message": "Message",
    "Loading complaint...": "Chargement de la plainte...",
    "Product description": "Description du produit",
    "Search for Tariff Codes": "Rechercher des codes tarifaires",
    "Frequently Asked Questions": "Questions fréquemment posées",
    "Languages": "Langues",
    "English": "Anglais",
    "French": "Français",
    "Swahili": "Swahili",
    "Cancel": "Annuler",
    "Search HS code or description": "Rechercher le code SH ou la description",
    "Attached files": "Fichiers joints",
    "Dashboard": "Tableau de bord"
};

module.exports = {fre};
