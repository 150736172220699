<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="registrationDetails.first_name"
          :label="t('First Name')"
          :error-messages="getErrorMessages('first_name')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="registrationDetails.last_name"
          :label="t('Last Name')"
          :error-messages="getErrorMessages('last_name')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="registrationDetails.email"
          :label="t('Email Address')"
          :error-messages="getErrorMessages('email')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="registrationDetails.cell_phone_no"
          :label="t('Cellphone Number')"
          :error-messages="getErrorMessages('cell_phone_no')"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="registrationDetails.country_id"
          :items="countryList"
          :label="t('Country of Residence')"
          :error-messages="getErrorMessages('country_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="registrationDetails.operator_id"
          :items="operatorList"
          :label="t('Operator Type')"
          :error-messages="getErrorMessages('operator_id')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="showOtherOperator">
      <v-col cols="12">
        <v-text-field
          v-model="registrationDetails.other_operator"
          :label="t('Other Operator')"
          :error-messages="getErrorMessages('other_operator')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="registrationDetails.gender"
          :items="genderList"
          :label="t('Gender')"
          :error-messages="getErrorMessages('gender')"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="changeShowPassword"
          :type="showPassword ? 'text' : 'password'"
          v-model="registrationDetails.password"
          :label="t('Password')"
          :error-messages="getErrorMessages('password')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="changeShowPasswordConfirm"
          :type="showPasswordConfirm ? 'text' : 'password'"
          v-model="registrationDetails.password_confirmation"
          :label="t('Confirm Password')"
          :error-messages="getErrorMessages('password_confirmation')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          :disabled="!canRegister"
          color="success"
          class="mr-4"
          @click="register"
          block
        >
          <v-icon>mdi-account-plus</v-icon>&nbsp;&nbsp;{{ t("Register") }}
        </v-btn>
      </v-col>
    </v-row>
    <overlay-custom
      :active="loading"
      :message="loadingMessage"
    ></overlay-custom>
  </v-container>
</template>

<script>
import MessageAlert from "../components/alert/MessageAlert";
import { mapState } from "vuex";
import { isEmpty, isNull, map } from "lodash";
import { mapErrors } from "@/lib/formUtils";
import OverlayCustom from "../components/OverlayCustom";

export default {
  name: "RegisterView",
  components: { OverlayCustom, MessageAlert },
  data() {
    return {
      registrationDetails: {
        first_name: null,
        last_name: null,
        cell_phone_no: null,
        email: null,
        password: null,
        password_confirmation: null,
        country_id: null,
        operator_id: null,
        other_operator: null,
        gender: null,
      },
      showPasswordConfirm: false,
      showPassword: false,
      errors: [],
      errorMessage: null,
      genderList: [
        {
          text: this.t("Female"),
          value: "Female",
        },
        {
          text: this.t("Male"),
          value: "Male",
        },
      ],
      loading: false,
      loadingMessage: null,
    };
  },
  computed: {
    countryList() {
      return map(
        !isNull(this.lookups) ? this.lookups.countries : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    operatorList() {
      return map(
        !isNull(this.lookups) ? this.lookups.operators : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    ...mapState({
      lookups: (state) => state.lookups,
    }),
    showOtherOperator() {
      return 6 === this.registrationDetails.operator_id;
    },
    canRegister() {
      return (
        !isEmpty(this.registrationDetails.first_name) &&
        !isEmpty(this.registrationDetails.last_name) &&
        !isEmpty(this.registrationDetails.cell_phone_no) &&
        !isEmpty(this.registrationDetails.email) &&
        !isEmpty(this.registrationDetails.password) &&
        !isEmpty(this.registrationDetails.password_confirmation) &&
        !isEmpty(this.registrationDetails.country_id) &&
        !isEmpty(this.registrationDetails.gender) &&
        ((this.showOtherOperator &&
          !isEmpty(this.registrationDetails.other_operator)) ||
          (!this.showOtherOperator &&
            !isNull(this.registrationDetails.operator_id)))
      );
    },
  },
  methods: {
    changeShowPasswordConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
    },
    changeShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async register() {
      const that = this;
      that.errorMessage = null;
      that.errors = [];
      that.loading = true;
      that.loadingMessage = that.t("Registering...");

      this.$store
        .dispatch("register", this.registrationDetails)
        .then(() => {
          that.$router.push({
            name: "login",
            query: {
              popup: "true",
              message: that.t("Registration complete!"),
              type: "info",
            },
          });
        })
        .catch((error) => {
          that.mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
          that.loadingMessage = null;
        });
    },
    mapErrors(errorDetails) {
      this.errors = mapErrors(errorDetails);
      this.errorMessage = errorDetails.message;
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
};
</script>
