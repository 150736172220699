<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <div v-if="ready">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="profileDetails.first_name"
            :label="t('First Name')"
            :error-messages="getErrorMessages('first_name')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="profileDetails.last_name"
            :label="t('Last Name')"
            :error-messages="getErrorMessages('last_name')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="profileDetails.email"
            :label="t('Email Address')"
            :error-messages="getErrorMessages('email')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="profileDetails.cell_phone_no"
            :label="t('Cellphone Number')"
            :error-messages="getErrorMessages('cell_phone_no')"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="profileDetails.country_id"
            :items="countryList"
            :label="t('Country of Residence')"
            :error-messages="getErrorMessages('country_id')"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="profileDetails.operator_id"
            :items="operatorList"
            :label="t('Operator Type')"
            :error-messages="getErrorMessages('operator_id')"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="showOtherOperator">
        <v-col cols="12">
          <v-text-field
            v-model="profileDetails.other_operator"
            :label="t('Other Operator')"
            :error-messages="getErrorMessages('other_operator')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="profileDetails.gender"
            :items="genderList"
            :label="t('Gender')"
            :error-messages="getErrorMessages('gender')"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="profileDetails.update_password"
            :label="t('Update Password')"
            color="green"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="profileDetails.update_password">
        <v-col cols="12">
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="changeShowPassword"
            :type="showPassword ? 'text' : 'password'"
            v-model="profileDetails.password"
            :label="t('Password')"
            :error-messages="getErrorMessages('password')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="profileDetails.update_password">
        <v-col cols="12">
          <v-text-field
            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="changeShowPasswordConfirm"
            :type="showPasswordConfirm ? 'text' : 'password'"
            v-model="profileDetails.password_confirmation"
            :label="t('Confirm Password')"
            :error-messages="getErrorMessages('password_confirmation')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            :disabled="!canUpdate"
            color="success"
            class="mr-4"
            @click="update"
            block
          >
            <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{ t("Update") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-skeleton-loader v-else type="article, actions"></v-skeleton-loader>
    <overlay-custom
      :active="loading"
      :message="loadingMessage"
    ></overlay-custom>
    <v-snackbar v-model="success">
      <div class="text-center">
        <v-icon large>mdi-check-circle-outline</v-icon>&nbsp;&nbsp;{{
          t("Profile")
        }}
        updated!
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import MessageAlert from "../components/alert/MessageAlert";
import { mapState, mapGetters } from "vuex";
import { isEmpty, isNull, map, cloneDeep, merge, omit } from "lodash";
import { mapErrors } from "@/lib/formUtils";
import OverlayCustom from "../components/OverlayCustom";

export default {
  name: "RegisterView",
  components: { OverlayCustom, MessageAlert },
  data() {
    return {
      profileDetails: {
        first_name: null,
        last_name: null,
        cell_phone_no: null,
        email: null,
        password: null,
        password_confirmation: null,
        country_id: null,
        operator_id: null,
        other_operator: null,
        gender: null,
        update_password: false,
      },
      success: false,
      showPasswordConfirm: false,
      showPassword: false,
      errors: [],
      errorMessage: null,
      genderList: [
        {
          text: this.t("Female"),
          value: "Female",
        },
        {
          text: this.t("Male"),
          value: "Male",
        },
      ],
      ready: false,
      loading: false,
      loadingMessage: null,
    };
  },
  computed: {
    countryList() {
      return map(
        !isNull(this.lookups) ? this.lookups.countries : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    operatorList() {
      return map(
        !isNull(this.lookups) ? this.lookups.operators : [],
        function (lookup) {
          return {
            text: lookup.name,
            value: lookup.id,
          };
        }
      );
    },
    ...mapState({
      lookups: (state) => state.lookups,
      userDetails: (state) => state.userDetails,
    }),
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
    }),
    showOtherOperator() {
      return 6 === this.profileDetails.operator_id;
    },
    canUpdate() {
      return (
        !isEmpty(this.profileDetails.first_name) &&
        !isEmpty(this.profileDetails.last_name) &&
        !isEmpty(this.profileDetails.cell_phone_no) &&
        !isEmpty(this.profileDetails.email) &&
        !isEmpty(this.profileDetails.country_id) &&
        !isEmpty(this.profileDetails.gender) &&
        ((this.showOtherOperator &&
          !isEmpty(this.profileDetails.other_operator)) ||
          (!this.showOtherOperator &&
            !isNull(this.profileDetails.operator_id))) &&
        ((this.update_password &&
          !isEmpty(this.profileDetails.password) &&
          !isEmpty(this.profileDetails.password_confirmation)) ||
          !this.update_password)
      );
    },
  },
  async mounted() {
    if (this.isLoggedIn) {
      this.loadingMessage = this.t("Loading...");
      this.loading = true;
      await this.$store.dispatch("userDetails");
      this.ready = true;
      this.profileDetails = merge(
        this.profileDetails,
        cloneDeep(this.userDetails)
      );
      this.loading = false;
      this.loadingMessage = null;
    } else {
      await this.$router.push({
        name: "login",
        query: {
          popup: "true",
          message: this.t("Unauthorized!"),
          type: "error",
        },
      });
    }
  },
  methods: {
    changeShowPasswordConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
    },
    changeShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async update() {
      const that = this;
      that.success = false;
      that.errorMessage = null;
      that.errors = [];
      that.loading = true;
      that.loadingMessage = that.t("Updating...");

      if (!this.profileDetails.update_password) {
        this.profileDetails = omit(this.profileDetails, [
          "password",
          "password_confirmation",
        ]);
      }

      this.$store
        .dispatch("updateProfile", this.profileDetails)
        .then(() => {
          that.success = true;
        })
        .catch((error) => {
          that.mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
          that.loadingMessage = null;
        });
    },
    mapErrors(errorDetails) {
      this.errors = mapErrors(errorDetails);
      this.errorMessage = errorDetails.message;
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
};
</script>
