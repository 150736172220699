<template>
  <v-container>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <message-alert :message="errorMessage" alert-type="error" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="loginDetails.email"
          :label="t('Email Address')"
          :error-messages="getErrorMessages('email')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="loginDetails.password"
          :label="t('Password')"
          type="password"
          :error-messages="getErrorMessages('password')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          :disabled="!canLogin"
          color="success"
          class="mr-4"
          @click="login"
          block
        >
          <v-icon>mdi-login</v-icon>&nbsp;&nbsp;{{ t("Login") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <router-link to="/forgot-password">{{
          t("Forgot your Password?")
        }}</router-link>
      </v-col>
    </v-row>
    <overlay-custom
      :active="loading"
      :message="loadingMessage"
    ></overlay-custom>
  </v-container>
</template>

<script>
import MessageAlert from "../components/alert/MessageAlert";
import { isEmpty } from "lodash";
import { mapErrors } from "@/lib/formUtils";
import OverlayCustom from "../components/OverlayCustom";

export default {
  name: "LoginView",
  components: { OverlayCustom, MessageAlert },
  data() {
    return {
      loginDetails: {
        email: null,
        password: null,
      },
      errors: [],
      errorMessage: null,
      loading: false,
      loadingMessage: null,
    };
  },
  computed: {
    canLogin() {
      return (
        !isEmpty(this.loginDetails.email) &&
        !isEmpty(this.loginDetails.password)
      );
    },
  },
  methods: {
    async login() {
      const that = this;
      that.errorMessage = null;
      that.errors = [];
      that.loading = true;
      that.loadingMessage = that.t("Logging in...");

      this.$store
        .dispatch("login", this.loginDetails, { root: true })
        .then(() => {
          that.$router.push({ path: "/complaints/view", query: { login: 1 } });
        })
        .catch((error) => {
          that.mapErrors(error.data);
        })
        .finally(() => {
          that.loading = false;
          that.loadingMessage = null;
        });
    },
    mapErrors(errorDetails) {
      this.errors = mapErrors(errorDetails);
      this.errorMessage = errorDetails.message;
    },
    hasError(field) {
      return !isEmpty(this.errors[field]);
    },
    getErrorMessages(field) {
      return this.errors[field];
    },
  },
};
</script>
